export const emotions: Record<string, string> = {
    "Love": 'Pleasure for a pleasant object',
    "Hate": 'hate an unpleasant object',
    "Happy-for": 'satisfied, happy for an event that is considered desiderable for someone else who we like',
    "Resentment": 'Suffering for an event that is desiderable for somebody else who we do not like',
    "Pity": 'Suffering for an event that is undesiderable for somebody else who we do like',
    "Gloating": 'Satisfied, happy for an event that is considered undesiderable for someone else who we do not like',
    "Hope": 'Happy for the prospective occurring of an event that is desiderable (plan accomplishment or value balancing)',
    "Fear": 'Sorry for the prospective occurring of an event that is undesiderable',
    "Satisfaction": 'For the confirm of an event that is desirable',
    "Fear-confirmed": 'For the confirm of an event that is undesirable',
    "Disappointment": 'For the confirm of the non-realization of an event that is desirable',
    "Relief": 'For the confirm of the non-realization of an event that is undesirable',
    "Joy": 'For an event that is desirable',
    "Distress": 'For an event that is undesirable',
    "Pride": 'For approving an own praiseworthy action',
    "Shame": 'For disapproving an own blameworthy action',
    "Admiration": 'For approving a praiseworthy action of somebody else',
    "Reproach": 'For disapproving a blameworthy action of somebody else',
    "Gratification": '(Joy+Pride), for approving an own praiseworthy action and being satisfied for the relative desirable event',
    "Remorse": '(Distress+Shame), for disapproving an own blameworthy action and being sorry for the relative undesirable event',
    "Gratitude": '(Joy+Admiration), for approving a praiseworthy action of somebody else and being satisfied for the relative desirable event',
    "Anger": '(Distress+Reproach), for disapproving a blameworthy action of somebody else and being sorry for the relative undesirable event'
}